import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import SEO from "../components/seo"

const useStyles = makeStyles(theme => ({
  pos: {
    "& p": {
      marginBottom: 0,
    },
  },
}))

export default ({ data }) => {
  const post = data.markdownRemark
  const classes = useStyles()

  return (
    <Layout>
      <SEO title={post.frontmatter.title} />
      <Paper>
        <Box p={4}>
          <Typography variant="h3">{post.frontmatter.title}</Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            {post.frontmatter.date}
          </Typography>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: post.html }}
            className={classes.pos}
            component="span"
          ></Typography>
        </Box>
        <Box pl={2} pb={2}>
          <Link to="/blog/">
            <Button size="small" color="primary">
              Back
            </Button>
          </Link>
        </Box>
      </Paper>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
      }
    }
  }
`
